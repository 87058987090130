import React, { useEffect } from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import * as CONST from "../CONSTANTS";
import './privacy-policy.scss';
// import ReactGA from "react-ga"

const PrivacyPolicy = () => {
  useEffect(() => {
    // ReactGA.initialize('UA-115903172-9');
    // ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <Layout layoutType={CONST.LAYOUT_ORDINARY_PAGE}>
      <Seo title="Privacy policy" />
      <div className="privacy-policy-section">
        <div className="container">
          <h4 className="c8" id="h.2ouk64kyjnr9">
            <span className="c1">Privacy Policy</span>
          </h4>
          <p className="c3">
            <span className="c6">
              Safeguarding the accuracy, confidentiality, and security of your
              personal information is our top priority. This Privacy Policy,
              accessible here and subject to periodic updates, outlines how we
              collects your personal information, both directly from you and
              about you, when you interact with our Lendmarx, LLC website
              (&quot;Website&quot;) or utilize our services
              (&quot;Services&quot;). The policy further explains how we utilize
              this information and to whom we might disclose it. Please note
              that this Privacy Policy pertains solely to the data collected by
              us or on our behalf. This data is acquired through the information
              you provide or from authorized and approved sources. It does not
              extend to data gathered through other websites, products, or
              services not sanctioned by us.
            </span>
          </p>
          <h4 className="c8" id="h.rzq420j5lvn6">
            <span className="c1">Consent</span>
          </h4>
          <p className="c3">
            <span className="c6">
              By engaging with our Website or acquiring our Services in any
              capacity, you acknowledge your acceptance of the terms,
              procedures, and guidelines outlined in this Privacy Policy (and
              any updates thereof). Furthermore, you grant your consent for us
              to gather, utilize, and share your information as detailed within
              this document. Should you disagree with our practices and
              policies, you retain the option to abstain from using our Website
              or Services. It is essential to recognize that your use of the
              Website and our Services is always subject to our Terms of Use
              (accessible here and subject to updates), which is hereby
              incorporated by reference. In instances where we employ
              capitalized terms in this Privacy Policy without providing
              definitions, these terms hold the meanings ascribed to them in the
              Terms. Your consent is granted for us and/or our service providers
              to conduct a credit check on you based on the information you have
              supplied.
            </span>
          </p>
          <h4 className="c8" id="h.64a5uyoq3ws8">
            <span className="c1">Email Disclosure</span>
          </h4>
          <p className="c3">
            <span className="c6">
              Should you request information from us, the email address you
              provide might be used to send you information about offers on
              products and services that we believe align with your interests.
              We may also associate an email address with your telephone number
              through our own records or use of third-party services and
              technologies, including cookies and pixels. If you&#39;ve
              subscribed to our email list, requested regular information, or
              received offers by email, you have the option to unsubscribe at
              any time using the instructions provided in each email or on the
              sign-up page.
            </span>
          </p>
          <h4 className="c8" id="h.i2ti0kiobr5">
            <span className="c1">Privacy Policy for USA</span>
          </h4>
          <p className="c3">
            <span className="c6">
              We adhere to the privacy regulations in each jurisdiction where we
              operate as part of our policy. Privacy legislation and individual
              privacy rights can vary from one country to another. This Privacy
              Policy specifically addresses activities governed by the privacy
              laws of the United States, as relevant.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              It&#39;s important to note that this Privacy Policy has specific
              boundaries and applicability. As a result, the rights and
              responsibilities outlined herein might not be accessible to all
              individuals or applicable in all countries. If you are uncertain
              about the relevance or extent of this Privacy Policy to your
              situation, please reach out to us for further clarification
              (contact details provided below).
            </span>
          </p>
          <h4 className="c8" id="h.bo10g1r46opo">
            <span className="c1">Individuals under the Age of 18</span>
          </h4>
          <p className="c3">
            <span className="c6">
              We are committed to not knowingly collecting, requesting, or
              retaining Personal Information from individuals under the age of
              18, and we do not knowingly permit such individuals to register
              for our Services. If you are below 18 years of age, please refrain
              from providing any Personal Information, such as your name,
              address, phone number, or email address, to us. Should we become
              aware that we have inadvertently gathered Personal Information
              from a minor under the age of 18 without verified parental
              consent, we will make reasonable efforts to remove that
              information from our records. If you have any concerns regarding
              this matter, please reach out to us.
            </span>
          </p>
          <h4 className="c8" id="h.2gtuirqcjpwy">
            <span className="c1">
              What Information Does This Privacy Policy Cover?
            </span>
          </h4>
          <p className="c3">
            <span className="c6">
              This Privacy Policy pertains to the handling of personal
              information. &quot;Personal Information&quot; refers to any
              unencrypted or non-deidentified information that can identify,
              relate to, describe, or be reasonably linked with an individual.
              This includes, but is not limited to, details such as your name,
              mailing address, email address, and telephone number. More
              specific information about the data we may gather is provided
              below.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              For residents of California, &quot;Personal Information&quot; will
              have the meaning as defined by the California Consumer Privacy Act
              of 2018 (&quot;CCPA&quot;). Refer to the section titled
              &quot;Notice to California Residents&quot; for further details.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Personal Information excludes information that has been
              deidentified, pseudonymized, anonymized, aggregated, or otherwise
              processed in a manner that renders it unable to be associated with
              a specific individual without using additional information. This
              additional information is kept separate and securely to prevent
              unauthorized re-identification of an individual (collectively
              referred to as &quot;De-Identified Personal Information&quot;).
            </span>
          </p>
          <h4 className="c8" id="h.pj6afluoexl">
            <span className="c1">What Personal Information do we Collect?</span>
          </h4>
          <p className="c3">
            <span className="c6">
              We might gather and retain various categories of Personal
              Information pertaining to the individuals we engage with. The
              kinds of Personal Information we acquire encompass the following:
            </span>
          </p>
          <table className="c28">
            <tr className="c0">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c16">CATEGORY</span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c16">EXAMPLES</span>
                </p>
              </td>
            </tr>
            <tr className="c20">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">Identifiers</span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">
                    Real name, alias, postal address, unique personal
                    identifier, online identifier, internet protocol (IP&rdquo;)
                    address, email address, or other similar identifiers
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c21">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">
                    Personal information described in the California Customer
                    Records statute (Cal. Civil Code &sect;1798.80(e))
                  </span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">
                    A name, or description, address, telephone number, or
                    employment, employment history, or any other financial
                    information. Note: Personal information included in this
                    category (1) does not include publicly available information
                    that is lawfully made available to the general public from
                    federal, state, or local government records, and (2) may
                    overlap with other categories
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c20">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">
                    Protected classification characteristics under California or
                    federal law
                  </span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">
                    Age (40 years or older), veteran or military status
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c20">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">
                    Internet or other electronic network activity information
                  </span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">
                    Browsing history, search history, information on a
                    consumer&rsquo;s interaction with a website, application, or
                    advertisement
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c0">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">Geolocation data</span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">Physical location or movements</span>
                </p>
              </td>
            </tr>
            <tr className="c20">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">
                    Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">
                    Electronic recording of customer or prospective customer
                    calls.
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c21">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">
                    Inferences drawn from any other category of personal
                    information
                  </span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c7">
                  <span className="c6">
                    Deriving information, data, assumptions, or conclusions from
                    facts, evidence, or another source of information or data to
                    create a profile reflecting a person&rsquo;s preferences,
                    characteristics, psychological trends, predispositions,
                    behavior, attitudes, intelligence, abilities, and aptitudes
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c19">
              <td className="c27" colSpan="1" rowSpan="1">
                <p className="c7 c24">
                  <span className="c6"></span>
                </p>
              </td>
              <td className="c23" colSpan="1" rowSpan="1">
                <p className="c7 c24">
                  <span className="c6"></span>
                </p>
              </td>
            </tr>
          </table>
          <h4 className="c8" id="h.c7ig83fqulyl">
            <span className="c1">Information You Provide Us</span>
          </h4>
          <p className="c3">
            <span className="c6">
              The Personal Information we gather via our Website, business
              partners, affiliates, or service providers may be acquired through
              the following means:
            </span>
          </p>
          <ul className="c9 lst-kix_3en688r5z5d9-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                Information you provide through webforms on our Website. This
                encompasses data you furnish when creating an online account,
                purchasing our Products or Services, subscribing to our
                e-newsletters or other communications, seeking information from
                us, submitting material on our forums (where permissible),
                engaging with customer support, reporting issues with our
                Website or Services, or engaging in other forms of communication
                with us.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Records and copies of your correspondence, including email
                addresses, if you reach out to us.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Your survey responses, which we or our service providers might
                request for research purposes.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Your search queries conducted on our Website
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Interactions with customer service/support
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Participation in loyalty/rewards programs
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Information from third-party websites and mobile applications
                (e.g., websites sharing information with us or advertising
                partners about online activities).
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Data from suppliers (e.g., companies providing consumer
                demographics and other data).
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Data acquired from mobile applications (parent, subsidiary, and
                affiliate brands).
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">Joint marketing partners.</span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">Online advertising companies.</span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">Social media companies.</span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">Other service providers.</span>
            </li>
          </ul>
          <p className="c3">
            <span className="c6">
              Data obtained in response to employment opportunities.
              Furthermore, we might collect your Personal Information in
              relation to financial-related Services you seek from us. This
              includes suggesting financial or other products based on our
              experience and the information you provide, to improve the
              likelihood of acceptance.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              We might also gather market-related data, including Personal
              Information, about market trends and activities influencing our
              operations. Such data could involve contract details, financial
              particulars, and other relevant information for market analysis
              and strategic planning. Additionally, we may amass information
              related to our media, investor, and public relations endeavors, as
              well as data connected to our interactions with financial experts
              and advisors.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              On occasion, we might engage third-party services within our
              operations, and these parties may furnish us with Personal
              Information they&#39;ve gathered as part of their service
              provision or otherwise. In such cases, we will take reasonable
              measures to confirm that these third parties have assured us of
              their authority to disclose your Personal Information to us.
            </span>
          </p>
          <h4 className="c8" id="h.psamgt44lsdy">
            <span className="c1">Privacy and our Website</span>
          </h4>
          <p className="c3">
            <span className="c6">
              You are welcome to explore our Website without divulging your
              identity or sharing any personal details, including your email
              address. During your visit, our web server might collect data
              concerning your interaction with our Website. This could encompass
              the IP address and domain used for access, browser type and
              version, the referring website, the entry and exit pages, the
              pages viewed during that IP session, and the country indicated by
              your IP address. We use this information for performance
              monitoring purposes (e.g., visits, average duration, page views)
              and for business enhancements to continually enhance our Website.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Furthermore, we gather the Personal Information that you provide
              through our Website, such as your name, address, and any other
              contact details you opt to provide. You may provide this
              information in the following ways:
            </span>
          </p>
          <ul className="c9 lst-kix_pcag4oqwyxl2-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                By utilizing the &quot;contact us&quot; section on our website;
                or
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                By communicating with a representative of our site via email
                using the designated hyperlinks.
              </span>
            </li>
          </ul>
          <p className="c3">
            <span className="c6">
              Should you request information from us, the email address you
              provide might be used to send you information about offers on
              products and services that we believe align with your interests.
              If you&#39;ve subscribed to our email list, requested regular
              information, or receive offers by email, you have the option to
              unsubscribe at any time using the instructions provided in each
              email or on the sign-up page.
            </span>
          </p>
          <h4 className="c8" id="h.9z8f91wyo9yk">
            <span className="c1">Our Website and Cookies</span>
          </h4>
          <h4 className="c8" id="h.w5d4p4zdqlkz">
            <span className="c1">
              Information We Collect Through Automatic Data Collection
              Technologies
            </span>
          </h4>
          <p className="c3">
            <span className="c6">
              As you navigate and interact with our Website, we utilize
              automatic data collection technologies to gather specific
              information about your device, browsing behaviors, and usage
              patterns, including:
            </span>
          </p>
          <ul className="c9 lst-kix_gydnpnuszioo-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                Details regarding your visits to our Website, encompassing
                traffic data, geolocation data, logs, communication data, and
                the resources you access and utilize on the Website.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Information concerning your computer, mobile device, and
                internet connection, such as your IP address, operating system,
                browser type, clickstream patterns, the URL of the preceding
                website you visited before reaching ours, the duration of your
                visit, and the pages viewed during your time on our Website.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Behavioral Tracking: We might also employ these technologies to
                compile insights about your online activities across time and
                various third-party websites or online services. This
                information might be associated with De-Identified Personal
                Information we collect. While we don&#39;t permit personalized
                third-party behavioral tracking, we may use De-Identified
                Personal Information to track users&#39; click or browsing
                patterns.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Keystrokes: We or our service providers might observe and
                collect keystrokes and cursor movements of Website visitors
                before purchasing a Product or Service from our Website (prior
                to having specific identifying information about you beyond your
                IP address). This information remains anonymous, and we
                don&#39;t initially link it to your identifiable identity.
                However, if you subscribe to receive our emails (e.g., through a
                popup box), we can subsequently link keystroke and cursor
                information to the provided email address. This data is used
                solely to enhance the Website for an improved user experience
                (e.g., better navigation, readability).
              </span>
            </li>
          </ul>
          <p className="c3">
            <span className="c6">
              The automatically collected information is primarily statistical
              data, which may include Personal Information, and we may either
              maintain it or associate it with Personal Information obtained
              through other methods or from third parties. This data assists us
              in enhancing the Website and delivering more tailored Products and
              Services. It enables us to estimate audience size, analyze usage
              patterns, store preferences, enhance and customize the Website,
              accelerate search functions, and recognize returning visitors.
            </span>
          </p>
          <h4 className="c8" id="h.kcp471lhypc">
            <span className="c1">Cookie Notice/Policy</span>
          </h4>
          <p className="c3">
            <span className="c6">
              The technologies we utilize for this automatic data collection
              encompass cookies, local storage cookies, web beacons, pixel
              tracking, GIFs, and IP addresses. Each of these is detailed below.
            </span>
          </p>
          <p className="c3">
            <span className="c6">Cookies (or browser cookies)</span>
          </p>
          <p className="c3">
            <span className="c6">
              A cookie is a small file stored on your computer or mobile
              device&#39;s hard drive. It may contain various data, such as the
              server&#39;s name that placed it, a unique identification number,
              and an expiration date (in some cases). These cookies are managed
              by your web browser (e.g., Internet Explorer, Firefox, Safari, or
              Google Chrome) on your device.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Our Website employs different types of cookies, each serving
              distinct purposes.
            </span>
          </p>
          <p className="c3">
            <span className="c6">Essential Cookies</span>
          </p>
          <p className="c3">
            <span className="c6">
              These cookies are indispensable for enabling you to navigate our
              Website and utilize its functionalities. They are necessary for
              services like shopping carts and electronic invoicing to function
              properly.
            </span>
          </p>
          <p className="c3">
            <span className="c6">Performance Cookies</span>
          </p>
          <p className="c3">
            <span className="c6">
              Performance cookies gather data about your interaction with our
              Website, such as the most frequently visited pages. This
              information empowers us to optimize the Website&#39;s performance
              and simplify navigation. Additionally, performance cookies allow
              our affiliates and partners to ascertain if you accessed our pages
              through their site and whether this visit resulted in using or
              purchasing a Product or Service from our Website, including the
              relevant product references. These cookies do not gather
              information that could identify you personally. All collected data
              is aggregated and thus remains anonymous.
            </span>
          </p>
          <p className="c3">
            <span className="c6">Functionality Cookies</span>
          </p>
          <p className="c3">
            <span className="c6">
              These cookies empower our Website to recall the choices you&#39;ve
              made while browsing. For instance, we can store your geographical
              location in a cookie to display the corresponding regional
              content. Preferences like text size, font, and other customizable
              elements of the Website can also be remembered. Functionality
              cookies might also track viewed products or videos to prevent
              redundancy. The data collected by these cookies is incapable of
              identifying you and does not track your browsing activity on
              external sites beyond our control.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              On certain pages of websites not managed by us, you might
              encounter third-party cookies.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              We also utilize cookies to implement tracking technology on our
              Website. This enables us to showcase tailored advertising on our
              Website, comprehend which content sections interest you the most,
              and determine the preferred Product or Service categories. This
              tracking relies on De-Identified Personal Information data. We
              will not merge this data with your other Personal Information
              without your explicit consent. Some of our service providers are
              permitted to place cookies on our Website. These companies may
              offer you the choice to opt out of cookie usage in the future. For
              more information, please contact the relevant third-party
              provider.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              You retain the ability to prevent future cookie usage at any time.
              By adjusting the appropriate setting in your browser, you can
              decline the acceptance of browser cookies. However, this action
              might impact your experience on our Website, potentially
              restricting access to certain sections. If your browser settings
              are not configured to reject cookies, our system will issue
              cookies when you visit our Website.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Local Storage Cookies: Certain functionalities of our Website may
              utilize local stored objects (or Adobe Flash cookies) to
              accumulate and store data about your preferences and navigation on
              our Website. These local storage cookies are not managed through
              the same browser settings as traditional browser cookies.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Web Beacons: Our Website pages and emails may contain tiny
              electronic files known as web beacons (also referred to as clear
              gifs, pixel tags, and single-pixel gifs). These elements enable
              us, for instance, to tally users who have visited specific pages
              or opened an email, and provide other relevant website statistics
              (such as tracking the popularity of specific website content and
              confirming system and server integrity).
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Pixel Tracking: Alongside the use of cookies, our Website may
              employ &quot;pixel tracking,&quot; a prevalent method also
              utilized in connection with advertisements on external sites.
              Pixel tracking entails the use of pixel tags, which are invisible
              to users and consist of concise lines of computer code. These tags
              measure the effectiveness of advertisements and generate both
              aggregate and specific usage statistics. A &quot;pixel tag&quot;
              is an undetectable tag positioned on particular website pages to
              monitor an individual user&#39;s activity. We may access these
              pixel tags to identify activities and interests, enhancing our
              ability to match our products, services, and offers with your
              preferences. For instance, if you reach our Website through an
              advertisement on another site, the pixel tag lets the advertiser
              track that their advertisement directed you to our Website.
              Additionally, if we link you to another website from our Website,
              we might determine that you engaged with a third-party website.
              This data is gathered to enhance our marketing, research, and
              other activities.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              GIF: We may employ small images referred to as clear GIFs to
              monitor user behavior, including collecting statistics about email
              openings.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              IP Address: When you access the Website, our servers (or those of
              our service providers) automatically capture specific log file
              details provided by your browser. These server logs might
              encompass data such as the web pages you accessed on the Website,
              your internet protocol (&quot;IP&quot;) address, browser type, and
              other insights into your interactions with the Website. Typically,
              these log files are regularly purged.
            </span>
          </p>
          <h4 className="c8" id="h.f6xc9touxmk">
            <span className="c1">
              Information We Collect from Third Parties
            </span>
          </h4>
          <p className="c3">
            <span className="c6">
              We may gather information that others provide about you when you
              utilize the Website or acquire data from alternative sources, and
              then combine it with the information we collect via the Website.
            </span>
          </p>
          <ul className="c9 lst-kix_2n1sz6qq2vct-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                Third Party Services: If you link, connect, or log in to your
                account using a third-party social media service (such as
                Facebook, Google, Instagram, Yelp, etc.), that service may
                transmit us information such as your registration and profile
                details from their platform. The nature of this information may
                vary and is governed by the terms of that service or your
                privacy settings within that service.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Other Sources: Subject to the extent allowed by applicable law,
                we may receive supplementary information about you, such as
                demographic details or fraud detection insights, from
                third-party service providers and/or partners. This information
                could be combined with the data we already possess about you.
                For instance, we might receive background check outcomes or
                fraud alerts from service providers like identity verification
                services, which contribute to our fraud prevention and risk
                assessment endeavors. Additionally, through partnerships, we
                might receive information about your interactions both on and
                off the Website, as well as insights from our partner
                advertising networks. Such third-party providers encompass
                various functions, including backend processing, order
                fulfillment, automation, video hosting platforms, email
                management, authentication, form handling, website usage
                tracking, calendar invitation and scheduling management, and
                database hosting and maintenance.
              </span>
            </li>
          </ul>
          <h4 className="c8" id="h.gl292q02lkz">
            <span className="c1">
              Third-Party Use of Cookies and Other Tracking Technologies
            </span>
          </h4>
          <p className="c3">
            <span className="c6">
              Certain content or applications, including advertisements, on the
              Website are furnished by third parties, such as advertisers, ad
              networks and servers, content providers, and application
              providers. These third parties may employ first-party or
              third-party cookies independently or alongside web beacons and
              similar tracking technologies to collect information about your
              interactions with our Website.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              First-Party and Third-Party Cookies: A first-party cookie is a
              cookie established by the domain name visible in your
              browser&#39;s address bar. Conversely, a third-party cookie is
              placed on a domain name distinct from the one visible in your
              browser&#39;s address bar. These cookies might be set when side
              resources (like images, JavaScript, iframes, etc.) load from a
              different domain or via an AJAX HTTP request to a third-party
              server. The data collected by these cookies, whether first-party
              or third-party, could be associated with your Personal
              Information. Alternatively, they might accumulate data, including
              Personal Information, about your online activities across various
              websites and online services, essentially tracking these
              activities. This information could be employed for delivering
              interest-based (behavioral) advertising or other targeted content.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Third-Party Tracking Technologies: We lack control over the
              tracking technologies utilized by these third parties and how they
              are employed. If you have inquiries regarding a specific
              advertisement or targeted content, it is advisable to contact the
              responsible provider directly. Further details on opting out of
              third-party advertiser tracking mechanisms can be found here.
            </span>
          </p>
          <p className="c3">
            <span>Google</span>
          </p>
          <ul className="c9 lst-kix_90sedz7y2wzt-0 start">
            <li className="c2 li-bullet-0">
              <span className="c15">Google Analytics: We utilize </span>
              <span className="c12">
                <a
                  className="c14"
                  href="https://www.google.com/analytics/learn/privacy.html"
                  target="_blank" rel="noreferrer"
                >
                  Google Analytics
                </a>
              </span>
              <span className="c4">
                &nbsp;(visit Google&rsquo;s website) and potentially other
                analytics services, along with some third-party service
                providers, to gain insights into the usage of our Website and
                Services. These service providers may place their own cookies in
                your browser. Our Privacy Policy pertains to our use of cookies
                and not the usage by third parties.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c15">
                Google AdSense Advertising: Our website incorporates Google
                AdSense Advertising or similar search engine and display network
                advertising. Google&#39;s advertising principles are available{" "}
              </span>
              <span className="c12">
                <a
                  className="c14"
                  href="https://support.google.com/adspolicy/answer/6008942"
                  target="_blank" rel="noreferrer"
                >
                  here
                </a>
              </span>
              <span className="c15">
                &nbsp;to ensure a positive user experience. We&#39;ve
                implemented features like (a) Remarketing with Google AdSense
                and (b) Google Display Network Impression Reporting.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Google DART Cookie: Google, acting as a third-party vendor,
                employs cookies to serve ads on our Website. The DART cookie
                enables Google to show ads to users based on their previous
                visits to our Website and other sites on the Internet. Users can
                opt-out of the DART cookie by accessing the Google Ad and
                Content Network privacy policy.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Cookie Interactions: We and third-party vendors like Google
                employ first-party cookies (such as Google Analytics cookies)
                and third-party cookies (like the DoubleClick cookie) or other
                third-party identifiers together. This helps compile data
                regarding user interactions with ad impressions and other ad
                service functions linked to our Website.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c15">
                Google DoubleClick Cookie: We collaborate with Google&#39;s
                DoubleClick Cookie, a third-party vendor utilizing cookies to
                deliver ads on our Website. The DoubleClick cookie enables
                Google and its partners to provide users with ads tailored to
                their visits to our Website or other online platforms. Users can
                opt out of the DoubleClick Cookie for interest-based advertising
                via the Google Ads Settings web page{" "}
              </span>
              <span className="c12">
                <a
                  className="c14"
                  href="https://myadcenter.google.com/home"
                  target="_blank" rel="noreferrer"
                >
                  here
                </a>
              </span>
              <span className="c15">.</span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Google Maps/Google Earth: Google Maps (including Google Maps
                API(s)) and Google Earth are used on our Website. These services
                may request access to your geolocation and are subject to their
                own terms of use and privacy policy.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c15">
                Google reCAPTCHA: We incorporate Google reCAPTCHA, which detects
                bots by collecting hardware and software details and sending
                this data to Google for analysis. For more details about Google
                reCAPTCHA, refer to{" "}
              </span>
              <span className="c12">
                <a
                  className="c14"
                  href="https://www.google.com/recaptcha/about/"
                  target="_blank" rel="noreferrer"
                >
                  here
                </a>
              </span>
              <span className="c15">&nbsp;and </span>
              <span className="c12">
                <a
                  className="c14"
                  href="https://policies.google.com/privacy"
                  target="_blank" rel="noreferrer"
                >
                  here
                </a>
              </span>
              <span className="c15">.</span>
            </li>
          </ul>
          <p className="c3">
            <span className="c6">
              You have the option to customize your Google advertising
              preferences through the Google Ad Settings page. Alternatively,
              you can choose to opt-out by visiting the Network Advertising
              Initiative Opt Out page or by employing the Google Analytics Opt
              Out Browser add-on.
            </span>
          </p>
          <h4 className="c8" id="h.qqnuhmkonhk3">
            <span className="c1">Why Do We Collect Personal Information? </span>
          </h4>
          <p className="c3">
            <span className="c6">
              We gather Personal Information to effectively manage, sustain, and
              advance our business and operations. This includes:
            </span>
          </p>
          <ul className="c9 lst-kix_lqh481fna2uz-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                Establishing, upholding, and managing our relationship with you
                to provide requested products and services. For instance,
                assisting you in obtaining loans, credit cards, or other
                products/services from third parties might involve conducting a
                credit check based on the information you&#39;ve provided,
                confirming your financial situation and eligibility.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Evaluating the products and services we offer to comprehend your
                preferences and enhance our offerings.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Transmitting promotional and relevant materials to you, within
                the confines of applicable laws.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Assessing the products and services you supply to collaborate
                effectively and meet our requirements.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Addressing your preferences and complying with your requests,
                such as using a specific contact number.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Safeguarding against errors, fraud, theft, and safeguarding our
                assets, including verifying your identity.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Ensuring compliance with applicable laws and regulations.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Pursuing other legitimate purposes with your consent.
              </span>
            </li>
          </ul>
          <p className="c3">
            <span className="c6">
              We might utilize your Personal Information to generate Aggregated
              Information (as defined below) to manage, sustain, and enhance our
              operations. These purposes encompass: (i) identifying client
              demographics; (ii) crafting and marketing benchmarks, reports,
              summary metrics, and predictive algorithms; and (iii) refining
              existing products/services or innovating new ones. Within this
              Privacy Policy, &quot;Aggregated Information&quot; refers to data
              that: (i) stems from compiling, amalgamating, and analyzing
              personal and other information; and (ii) is anonymized.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              How Do We Use and Disclose Your Personal Information:
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              We may employ or disclose your Personal Information for the
              intents detailed in this Privacy Policy (including scenarios where
              we or our third-party business affiliates conduct credit checks to
              confirm your financial status and eligibility for requested
              products/services). Additionally, we might use or disclose your
              Personal Information for purposes to which you&#39;ve granted
              consent. The information we collect from you or that you provide,
              including Personal Information, serves one or more of the
              following aims:
            </span>
          </p>
          <ul className="c9 lst-kix_wjq81vqx120o-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                &bull; To display our Website and its content to you.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                &bull; To offer you information and answer your queries about
                Products or Services you request from us, along with updates on
                new products, services, discounts, special promotions, upcoming
                events, and features/offers we believe will interest you.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To provide you with the requested Products, Services, or
                information.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To send you notices about your account, including expiration and
                renewal notifications.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To inform you about changes to our Website, policies, terms,
                offered Products or Services, or your account.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To process your account application and any modifications to
                your account details.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To handle Personal Information or other data you submit to us.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To enable your participation in interactive features on our
                Website.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To contact you about our own and third-party products and
                services that might intrigue you.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To grant access to restricted sections of our Website, such as
                areas accessible to user accounts.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To refine and enhance our products and Services through
                activities like internal research, user trend analysis, and
                demographics/interest measurement.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                For internal purposes like Website and system administration,
                internal audits, and reviews.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                For analyzing Service usage, diagnosing technical issues,
                maintaining security, and personalizing content.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To operate, maintain, and deliver the features and functionality
                of our Services.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To share usage statistics of the Website and related information
                with our service providers.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To inform you about data privacy incidents or fulfill legally
                mandated information dissemination.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To reach out regarding promotions, contests, or sweepstakes
                you&#39;ve taken part in.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To request your involvement in ratings, reviews, surveys, focus
                groups, or other initiatives aiding our product and Service
                enhancement.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To fulfill any other purpose for which you provide Personal
                Information.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                For any other manner described and consented to when you provide
                the information.
              </span>
            </li>
          </ul>
          <p className="c3">
            <span className="c6">
              We utilize cookies, clear gifs, and log file data for the
              following purposes: (a) To remember information, ensuring you
              don&#39;t have to re-enter it during your visit or on subsequent
              visits to the Website; (b) To provide tailored, personalized
              content and information; (c) To assess the effectiveness of our
              Service; (d) To track aggregate metrics like total visitors,
              traffic, and demographic trends; (e) To diagnose and resolve
              technology issues associated with specific IP addresses, reported
              by our users or engineers; and, (f) To facilitate efficient access
              to your information after signing in.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Your provided Personal Information, as well as information
              obtained from other sources, may be used to enhance our
              understanding of your preferences. This helps us anticipate your
              potential interests in other products, services, and information.
              This process involves automated decisions based on your
              information, aimed at tailoring our interactions to make them more
              relevant and engaging. If you wish to object to this practice, you
              can do so by reaching out to us.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Furthermore, the information we gather from you may be employed to
              allow us to present advertisements to the intended audiences of
              our advertisers. While we don&#39;t disclose your Personal
              Information for such purposes without your consent, if you engage
              with an advertisement by clicking or otherwise interacting with
              it, the advertiser might assume that you meet their targeted
              criteria.
            </span>
          </p>
          <h4 className="c8" id="h.79ouxhnsfj7w">
            <span className="c1">Why Do We Collect Personal Information? </span>
          </h4>
          <p className="c3">
            <span className="c6">Text Messages</span>
          </p>
          <p className="c3">
            <span className="c6">
              You hereby provide consent to receive informational and marketing
              communications and messages from us and our service providers
              regarding mortgage services. These communications may be conveyed
              by live agents, artificial, automated, or pre-recorded voices, via
              email, and through Short Message Service (&quot;SMS&quot;) text
              messages to the phone number you have provided, whether it is your
              residence, mobile, or another contact number (hereinafter
              collectively referred to as &quot;Contact Info&quot;). Such
              communications may be initiated manually or by automated telephone
              dialing systems. In relation to SMS messages, you acknowledge that
              standard message and data rates may be applicable.
            </span>
          </p>
          <p className="c3">
            <span>
              This consent extends even if your Contact Info is listed on our or
              our service providers&#39; Do Not Call / Do Not Email List, a
              State or National Do Not Call Registry, or any other Do Not
              Contact List. You agree to promptly notify us if you cease using a
              specific number or address that you have previously provided to
              us. It is important to note that granting consent for the contact
              described in this section is not a requirement for purchasing our
              services, and you acknowledge your ability to withdraw this
              consent at any time. To do so, you can send an email to{" "}
            </span>
            <span className="c26">
              <a className="c14" href="mailto:unsubscribe@lendmarx.com">
                unsubscribe@lendmarx.com
              </a>
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              You also acknowledge that automated messages or
              artificial/prerecorded voices may be utilized when the call is
              answered, whether by you or another individual. Additionally, you
              agree and acknowledge that we and/or our service providers may
              listen, record, and retain call recordings between us, our service
              providers, and you for quality assurance or other lawful purposes,
              without prior notice, as permitted by relevant laws.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Our services might include SMS messages, with the potential of
              delivering up to five messages daily to your wireless device.
              These messages may pertain to your information, purchases, Website
              updates, surveys, or other informational and service-related
              matters, including critical security updates. You maintain the
              option to discontinue these messages by replying &quot;STOP&quot;
              to the SMS message received. Upon sending &quot;STOP,&quot; an SMS
              message confirming your unsubscribed status will be sent.
              Subsequently, you will no longer receive SMS messages from us.
              Should you choose to rejoin, simply follow the initial signup
              process, and SMS messages will resume. For assistance, respond to
              a text with &quot;HELP.&quot; Message and data rates may be
              incurred, contingent upon your cell phone plan. Carriers bear no
              responsibility for delayed or undelivered messages. Alternatively,
              you may submit a request to halt SMS messages by sending an email
              to us, specifying the email address and phone number associated
              with your registration.
            </span>
          </p>
          <h4 className="c8" id="h.z3p8h3hqbp0s">
            <span className="c1">Social Media Plugins</span>
          </h4>
          <p className="c3">
            <span className="c6">
              We incorporate social media application program interfaces or
              plug-ins (&quot;Plug-ins&quot;) from various social networks,
              including but not limited to Facebook, Google+, LinkedIn, Xing,
              Twitter, Instagram, Tumblr, Pinterest, and possibly others, into
              our Website. If you choose to register as a user on our platform,
              you might have the option to sign in using your Facebook or other
              social media site credentials.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              For instance, when you visit our Website, these Plug-ins establish
              a direct link between your browser and the respective social
              network&#39;s server. This enables the social network to receive
              information about your visit to our Website, including your IP
              address. If, while logged into your Facebook account, you click
              the &quot;Like&quot; button on our Website, you can associate our
              Website&#39;s content with your Facebook profile. Consequently,
              Facebook may link your visit to our Website with your user
              account. Please be aware that as the Website provider, we do not
              receive any information about the content of the data transmitted
              or its utilization by Facebook. If you prefer Facebook not to link
              your visit to our Website with your user account, we recommend
              logging out of your Facebook account.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              By taking this action, you grant us permission to access certain
              information from your social media site account, which can include
              your public social media profile (subject to your privacy settings
              on the social media site), email address, interests, likes,
              gender, birthday, education history, relationships, current city,
              personal description, photos, friend list, and details about your
              social media site friends who may share common connections with
              other users. Plug-ins may automatically transmit information about
              you to the respective platform without requiring any action on
              your part. This information could encompass your user
              identification number on the platform, the website you are
              currently visiting, and more. Engaging with a Plug-in leads to the
              direct transfer of information to that Plug-in&#39;s corresponding
              social network, potentially rendering such information visible to
              others on that platform.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              It is important to note that the privacy policies governing
              Plug-ins are dictated by the respective social network&#39;s terms
              and conditions, not our Privacy Policy. For detailed insight into
              the privacy practices of a particular platform, you can consult
              the privacy policy available on their official website.
            </span>
          </p>
          <h4 className="c8" id="h.80lddqp2j2dq">
            <span className="c1">
              How And To Whom Do We Disclose Your Personal Information?
            </span>
          </h4>
          <p className="c3">
            <span className="c6">
              We may share your Personal Information with our employees,
              contractors, consultants, and other relevant parties who require
              such information to support us in managing our relationship with
              you. This includes third parties that aid us in delivering
              services to you and those whose services contribute to our
              business operations.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Such third parties could encompass lenders and dealerships that
              intend to provide you with goods or services. Related parties
              involved in processing and storing your Personal Information for
              our benefit may also be included.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              For instance, our third-party service providers may periodically
              offer information technology, data processing,
              advertising/marketing, and call center services to facilitate our
              business operations. As a consequence, your Personal Information
              might be collected, utilized, processed, stored, or disclosed in
              locations such as the United States of America, Canada, Europe
              (including the United Kingdom and Russia), India, or Asia
              (including China). Consequently, your Personal Information may be
              accessed, stored, or used in other countries, potentially subject
              to their respective legal frameworks. For example, information may
              be revealed in response to legitimate requests or demands from
              government authorities, courts, or law enforcement agencies in
              these countries.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              In scenarios where you have explicitly requested it or
              demonstrated it through your actions, we may share your Personal
              Information with individuals who are considering your application
              for a loan, credit card, or another product or service. In
              relation to this, we and/or our third-party business affiliates
              might perform a credit check on your behalf to verify your
              financial situation and your eligibility for the requested
              products and services.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Furthermore, your Personal Information may be disclosed or
              transferred to another entity during a change in ownership,
              security interest grant, or the completion of a business
              transformation, such as an asset or share sale, merger, joint
              venture, or similar arrangement. However, this party would be
              bound by appropriate agreements or obligations to use or disclose
              your Personal Information in line with the provisions of this
              Privacy Policy, unless you provide an alternative consent.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Additionally, your Personal Information might be revealed in the
              following situations:
            </span>
          </p>
          <ul className="c9 lst-kix_g7jdp52kceiz-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                as authorized or mandated by relevant laws or regulatory
                obligations;
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                to adhere to valid legal proceedings like search warrants,
                subpoenas, or court directives;
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                as previously mentioned, to facilitate the management or
                fulfillment of your objective to secure credit from one or more
                third parties;
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                to safeguard the rights and assets of our platform;
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                in cases of emergencies or when essential to ensure the safety
                of individuals or a collective;
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">with your explicit consent.</span>
            </li>
          </ul>
          <h4 className="c8" id="h.f9ah5ph1awqe">
            <span className="c1">How We Share Your Personal Information</span>
          </h4>
          <p className="c3">
            <span className="c6">
              We may or do reveal your Personal Information, either in entirety
              or partially, to the following categories of third parties, and
              for one or more of the subsequent purposes:
            </span>
          </p>
          <ul className="c9 lst-kix_8iyjvi26vsb2-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                Data storage or hosting providers to ensure the secure storage
                and transmission of your data
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Database and software service providers to manage and monitor
                your data
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Technology providers aiding in the development and maintenance
                of our Website
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Identity management providers for authentication purposes
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Legal and compliance consultants, such as external counsel,
                external auditors, or tax consultants
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Manufacturers, retailers, and wholesalers for processing and
                overseeing rebates, discounts, offers, loyalty rewards, and
                similar aspects
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Outbound call center providers conducting outreach on our behalf
                regarding our products and services
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Survey and research providers conducting studies on our behalf
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Publishers and learning providers developing products on our
                behalf
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Learning technology and online event providers for delivering
                and enhancing web events and learning programs, and tracking
                your progress
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Advertising partners, including social media providers, for
                targeted advertisement distribution
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Independent third-party list renters for approved one-time mail
                or email
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Marketing providers who may engage in selling mailing lists
              </span>
            </li>
          </ul>
          <p className="c3">
            <span className="c6">
              Sharing with Service Providers: We might share your Personal
              Information with third parties to deliver or enhance the Services
              provided to you. This includes sharing your Personal Information
              with third-party service providers who perform tasks on our behalf
              (&quot;Service Providers&quot;). This encompasses, but is not
              limited to, Service Providers offering services related to
              outbound and/or inbound communications, data analysis, credit
              checks, screening checks, collection services, marketing support,
              customer information management, customer or support services
              provision on our behalf, postal mail and email dispatch,
              eliminating redundant information from customer lists, furnishing
              search results and links (including paid listings and links),
              processing credit card payments, or overseeing our conferences and
              events. These Service Providers might access your Personal
              Information to furnish services to us or on our behalf. If we
              engage Service Providers for these purposes, their use of your
              Personal Information will be governed by confidentiality
              obligations, and their usage will be limited to providing the
              specified services to us. We may store Personal Information in
              locations beyond our direct control (e.g., on servers or databases
              situated or co-located with hosting Service Providers).
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Mandatory Disclosures: Except as outlined in this Privacy Policy,
              we will not reveal your Personal Information to any third party
              unless legally required to do so, such as by law, court order,
              legal process, or subpoena. This includes responding to
              governmental, regulatory, or licensing requests or situations
              where we believe such action is necessary to: (a) adhere to the
              law, comply with legal process served on us or our affiliates,
              subsidiaries, contracted vendors, or affinity partners, or
              investigate, prevent, or act against suspected or actual illegal
              activities; (b) enforce our Terms or customer agreement (including
              for billing and collection purposes); (c) safeguard against
              liability; (d) address and defend ourselves against third-party
              claims or allegations; (e) assist government enforcement agencies
              or meet national security requirements; (f) safeguard our Website
              and Services&#39; security or integrity; or (g) exercise or
              safeguard our rights, property, or personal safety, as well as the
              rights, property, or personal safety of our users or others.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              We will aim to inform you about such requests unless: (i) legal
              process, court order, or applicable law prohibits notice; or (ii)
              we believe that providing notice would be ineffective,
              counterproductive, pose a risk of injury or bodily harm to an
              individual or group, or lead to or exacerbate a risk of fraud
              against us, our users, our Website, or our Services. In instances
              where we comply with legal requests without prior notice due to
              these reasons, we will endeavor to notify the affected user after
              the fact if we determine in good faith that we are no longer
              legally restricted from doing so, and if the risk scenarios
              mentioned in this paragraph are deemed not applicable.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              The identity and categories of such third parties are likely to
              change during the lifespan of your account. We stipulate that our
              third-party service providers only use your Personal Information
              as required to offer the requested services to us, and each
              service provider adheres to a set of terms aligned with the
              relevant sections of this Privacy Policy.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Disclosure of De-Identified Personal Information: Sharing of
              De-Identified Personal Information: We may share De-Identified
              Personal Information with third parties for various purposes. Such
              information, as well as non-Personal Information, may be
              aggregated for system management and to monitor Website usage.
              This aggregation helps assess the number of website visits,
              average visit duration, pages viewed, and other relevant
              statistics, enabling us to enhance content and user experience.
              De-Identified Personal Information or anonymous data (including,
              but not limited to, anonymous usage metrics, referring/exit pages
              and URLs, IP address, platform types, click count, etc.) may be
              shared with interested third parties for any intended purpose. We
              retain the right to disclose, sell, rent, or otherwise use your
              De-Identified Personal Information, potentially receiving
              compensation in return.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Your Consent to Disclosure/Transfer/Sale of Your Personal
              Information: By providing us with your Personal Information,
              De-Identified Personal Information, and other supplied information
              (&quot;Transferred Information&quot;), you consent to our
              potential disclosure of this data to a prospective or actual buyer
              or acquirer of our company or other successors. This disclosure
              aims to facilitate considerations of mergers, divestitures,
              restructuring, reorganizations, dissolutions, changes in control,
              or sales/transfers of some or all of our assets (referred to as a
              &quot;Transfer&quot;), whether as a going concern or within
              bankruptcy, liquidation, or other legal proceedings where our
              Personal Information constitutes part of the transferred assets.
              You agree to, and hereby consent to, our assignment, transfer,
              conveyance, and/or licensing (via contract, merger, or operation
              of law) of any or all rights, either wholly or partially, in or to
              Transferred Information, including your consents. This can occur
              with or without your notice and without requiring your additional
              consent.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              We cannot guarantee the use or transfer of Transferred Information
              during scenarios like bankruptcy, reorganization, insolvency,
              receivership, or an assignment for the benefit of creditors. By
              sharing any Personal Information, you explicitly consent to the
              use and/or transfer of Transferred Information in conjunction with
              a Transfer. Furthermore, except as legally mandated, we bear no
              responsibility for any security breaches by third parties or
              actions taken by third parties who receive Transferred Information
              disclosed to us.
            </span>
          </p>
          <h4 className="c8" id="h.12vzgpykaga2">
            <span className="c1">
              Data Transfer/Access Outside of the United States
            </span>
          </h4>
          <p className="c3">
            <span className="c6">
              Our headquarters are located in both Canada and the United States.
              The Personal Information gathered by us or our service providers
              might be stored and processed on servers located within or outside
              the United States, as well as in facilities across the globe where
              we and our service providers operate. Consequently, certain
              information may be accessible to individuals or entities outside
              the United States who are engaged in providing services for us.
              This means that we and our service providers may transfer your
              Personal Information to, or access it from, jurisdictions that may
              not offer data protection standards equivalent to those in your
              home jurisdiction. We will take reasonable measures to ensure that
              your Personal Information benefits from an adequate level of
              protection in the jurisdictions where it is processed.
            </span>
          </p>
          <h4 className="c8" id="h.dbtnmjmriih9">
            <span className="c1">Security</span>
          </h4>
          <p className="c3">
            <span className="c6">
              We have established safeguards designed to protect your Personal
              Information against accidental loss and unauthorized access, use,
              alteration, and disclosure. Your Personal Information is stored
              behind secure networks and firewalls, and it is accessible only to
              our authorized personnel and a limited number of Service Providers
              who possess special access privileges and are bound to maintain
              its confidentiality. Regular security scans are conducted on our
              Website to identify and address any potential vulnerabilities,
              ensuring a secure browsing experience.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Any payment transactions you make will be conducted through
              third-party payment processors or gateways that employ appropriate
              security protocols to safeguard your information. We do not
              collect or retain complete credit card numbers.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Our agents, contractors, service providers, and partners who
              require access to your Personal Information to fulfill services on
              our or your behalf are also obligated to maintain its
              confidentiality in line with this Privacy Policy. They are
              prohibited from disclosing the information to external parties or
              using it for any purpose other than providing the services as
              required by us or our agreement with them.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              The security of your information also depends on your actions.
              Maintaining good internet security practices is crucial. When you
              have password-protected access to your account or specific parts
              of the Website or Services, it&#39;s your responsibility to keep
              your password confidential and not share it with anyone.
              Preventing unauthorized access to your account and Personal
              Information is important. Choose and safeguard your password
              properly and restrict access to your computer, mobile device, and
              browser by logging out after using your account.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              If your email or social media accounts are compromised, they could
              be used to gain unauthorized access to your account with us if
              you&#39;ve shared those details or granted access through those
              accounts. In case of such a compromise, promptly update your
              account credentials with us, especially ensuring that the
              compromised account can&#39;t grant access to your account with
              us.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Information shared in public areas might be visible to other
              users. Be cautious when sharing sensitive information. We will
              never request your password or other account login details via
              email. If you receive such an email, please forward it to us for
              investigation.
            </span>
          </p>
          <h4 className="c8" id="h.ckwi57b0x6zk">
            <span className="c1">Data Retention</span>
          </h4>
          <p className="c3">
            <span className="c6">
              The duration for which we keep your Personal Information is
              contingent upon the purposes for which we utilize it. We will hold
              onto your Personal Information for the duration of your active
              account or as long as you remain a registered user of our
              Services. Additionally, we may retain it for other legitimate
              business motives, such as tax, legal, or operational necessities.
              After these periods, we will not retain your information for
              longer than what is stipulated by applicable law, our records
              retention policy, or what&#39;s reasonably needed for internal
              reporting, reconciliation, or to fulfill any information requests
              you may have. This retention timeline is subject to potential
              adjustments based on our periodic review.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Upon the conclusion or deactivation of your user account, we may
              maintain your profile details and all information shared in public
              areas of the Website. Although we may retain your Personal
              Information and other data post-account termination or
              deactivation, we will treat it as confidential as outlined in the
              Terms and this Privacy Policy, and in compliance with relevant
              laws. We possess the right to delete all your Personal Information
              and other data upon termination of your user account without prior
              notification.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              We may choose to retain De-Identified Personal Information for a
              duration that we consider suitable.
            </span>
          </p>
          <h4 className="c8" id="h.z8nb8uhgqpr6">
            <span className="c1">Your Consent is Important to Us</span>
          </h4>
          <p className="c3">
            <span className="c6">
              Maintaining your privacy is of utmost importance to us, which is
              why we gather, utilize, and reveal your Personal Information only
              with your consent. Depending on the sensitivity of the
              information, your consent may be expressed, implied, or deemed
              (through an opt-out method). Express consent can be granted
              orally, electronically, or in writing. Implied consent arises when
              your actions or inaction reasonably indicate agreement. For
              instance, when you engage in an agreement with us, we assume your
              consent for collecting, using, and disclosing your Personal
              Information for tasks connected to that agreement, as well as any
              other purposes communicated to you at that juncture.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Typically, we will request your consent during the point of
              collecting your Personal Information. In specific situations, we
              might obtain your consent after collection but before employing or
              revealing your Personal Information. If we intend to utilize or
              disclose your Personal Information for a purpose not previously
              specified (as outlined in this Privacy Policy or separately), we
              will strive to notify you of that purpose before executing such
              use or disclosure.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              However, as previously detailed, we may gather, use, or disclose
              your Personal Information without your awareness or consent as
              allowed or required by applicable laws or regulatory stipulations.
            </span>
          </p>
          <h4 className="c8" id="h.5x1qzw9mltmn">
            <span className="c1">Choice/Opt-Out</span>
          </h4>
          <p className="c3">
            <span className="c6">
              You have the right to modify or retract your consent at any point,
              taking into account legal or contractual obligations and providing
              reasonable notice. To do so, you can get in touch with our Privacy
              Officer using the contact details provided below. Any
              communications related to changing or withdrawing your consent
              should be in written form and directed to our Privacy Officer.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              However, please note that in specific situations, withdrawing your
              consent might impact our ability to continue offering products or
              services that require the collection, use, or disclosure of your
              Personal Information.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Unless you inform us otherwise, we will assume that you have
              granted consent for the collection, use, and disclosure of your
              Personal Information in accordance with the explanations provided
              in this Privacy Policy.
            </span>
          </p>
          <h4 className="c8" id="h.so3ahw5vz9u3">
            <span className="c1">
              How is Your Personal Information Protected?
            </span>
          </h4>
          <p className="c3">
            <span className="c6">
              We strive to uphold suitable physical, technical, and procedural
              measures tailored to the sensitivity of the specific Personal
              Information. These precautions are intended to mitigate the risks
              of loss, unauthorized access, copying, use, alteration, or
              disclosure of your Personal Information.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Ensuring the security of your Personal Information is a top
              priority for us. If you encounter any incident involving the loss,
              unauthorized access, or disclosure of Personal Information under
              our custody or control, please promptly inform our Privacy
              Officer.
            </span>
          </p>
          <h4 className="c8" id="h.duwxj65z1ufd">
            <span className="c1">Updating Your Personal Information</span>
          </h4>
          <p className="c3">
            <span className="c6">
              Maintaining accurate and up-to-date information in our records is
              crucial. Should your Personal Information change while our
              relationship is ongoing, kindly notify our Customer Care
              department about the update. If you encounter any challenges with
              the Customer Care department, please feel free to reach out to our
              Privacy Officer using the provided contact details.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              There might be cases where we cannot accommodate your request to
              modify your Personal Information. Instead, we may choose to add an
              alternative note to the relevant record in such situations.
            </span>
          </p>
          <h4 className="c8" id="h.80b0vootl1mx">
            <span className="c1">Access to Your Personal Information</span>
          </h4>
          <p className="c3">
            <span className="c6">
              You have the option to request access to your Personal
              Information. If you wish to review, verify, or correct your
              Personal Information, please get in touch with our Customer Care
              department. In the event that our Customer Care department is
              unable to assist, please reach out to our Privacy Officer using
              the provided contact details. It&#39;s important to note that any
              such requests should be submitted in writing.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              When making a request to access your Personal Information, please
              be aware that we might ask for specific details to confirm your
              identity and your right to access. This will also help us locate
              and provide you with the relevant Personal Information we hold
              about you. There could be a fee associated with accessing your
              Personal Information, which we will inform you about in advance.
              If you require guidance in formulating your request, you can
              contact our Customer Care department. If the Customer Care
              department is unable to assist, please connect with our Privacy
              Officer using the provided contact information.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              While you have the right to access your Personal Information,
              it&#39;s important to understand that this right is not absolute.
              In some cases, applicable laws or regulatory requirements might
              allow or compel us to decline providing certain or all of the
              Personal Information we hold about you. Additionally, there could
              be instances where the Personal Information has been disposed of,
              erased, or anonymized in accordance with our obligations and
              practices for retaining records.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              In situations where we cannot grant you access to your Personal
              Information, we will make an effort to provide you with an
              explanation, considering any legal or regulatory limitations.
            </span>
          </p>
          <h4 className="c8" id="h.jdus798h2wpw">
            <span className="c1">NOTICE TO CALIFORNIA RESIDENTS</span>
          </h4>
          <p className="c3">
            <span className="c6">
              We are obligated to comply with the California Consumer Privacy
              Act of 2018 (&quot;CCPA&quot;) by providing California residents
              with an explanation of how we collect, use, and share their
              Personal Information, as well as informing them of the rights and
              choices available to them concerning the handling of their
              Personal Information (referred to as the &quot;Notice&quot;).
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              Under California law, residents are afforded certain privacy
              rights, including the following:
            </span>
          </p>
          <ul className="c9 lst-kix_c5td0g4hus0m-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                Access to Personal Information: Those exercising this right can
                receive further details about the sources from which we collect
                information, the purposes for which we collect and share
                Personal Information, the information we hold about them, and
                the categories of parties with whom we share their information.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Disclosure: Individuals exercising this right can request the
                deletion of their collected Personal Information, although
                certain purposes may permit us to retain it. &bull;
                Non-Discrimination: Users can freely exercise these rights
                without fear of being denied goods or services based on their
                choices.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Deletion: Individuals exercising this right can ask us to delete
                their Personal Information we have collected, though we may be
                permitted to retain Personal Information for certain purposes.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Non-Discrimination: Users can freely exercise these rights
                without fear of being denied goods or services based on their
                choices.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                &quot;Opt Out&quot; of Sales: According to applicable law, we
                might share Personal Information for monetary or valuable
                consideration (referred to as a &quot;sale&quot; under
                California law). You have the option to opt out of such
                &quot;sales&quot; of your Personal Information to third parties.
                You can exercise this right by sending an email to us using the
                contact information provided below.
              </span>
            </li>
          </ul>
          <h4 className="c8" id="h.ce6e2tdz31gx">
            <span className="c1">CCPA Scope and Exclusions</span>
          </h4>
          <p className="c3">
            <span className="c6">
              The information provided in this Notice regarding our Privacy
              Practices and Privacy Rights under the CCPA pertains exclusively
              to California residents whose interactions with us fall within the
              following categories:
            </span>
          </p>
          <ul className="c9 lst-kix_72m2o0pg868a-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">Visiting our consumer websites,</span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">Subscribing to email alerts,</span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Participating in discussions or contributing to our blogs
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Establishing an account, which may or may not include financial
                products or services
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Applying for job openings on our websites or third-party sites
                (please note that Privacy Rights under the CCPA do not extend to
                job applicants)
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                The information outlined in this Notice about our Privacy
                Policies and CCPA Privacy Rights does not cover the Personal
                Information we collect, use, or disclose pertaining to:
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Consumers who initiate or complete the application process for
                financial products or services. This is due to such information
                being subject to the federal Gramm-Leach-Bliley Act
                (&quot;GLBA&quot;) and its implementing regulations, or the
                California Financial Information Privacy Act (&quot;FIPA&quot;).
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Representatives of businesses that seek to obtain our products
                or services, or those intending to provide products or services
                to us.
              </span>
            </li>
          </ul>
          <p className="c3">
            <span className="c6">
              It&#39;s important to note that the CCPA imposes limitations on
              these rights. For instance, it prohibits us from providing certain
              sensitive information in response to an access request and defines
              specific circumstances in which we are obligated to comply with a
              deletion request. We will address requests for information and
              access to the extent that we can reasonably associate the
              information we maintain with the identifying details provided in
              your request. If your request is denied, we will communicate our
              decision to you.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              You are entitled to exercise the aforementioned rights without
              experiencing any form of discrimination.
            </span>
          </p>
          <h4 className="c8" id="h.pv139hiyseif">
            <span className="c1">Identity verification</span>
          </h4>
          <p className="c3">
            <span className="c6">
              The CCPA mandates that we authenticate the identity of the person
              making a request to access or delete Personal Information before
              furnishing a substantial response to the said request. Upon
              submitting a request, we will require you to confirm your
              identity.
            </span>
          </p>
          <h4 className="c8" id="h.fhfbbsekx47l">
            <span className="c1">Authorized agents</span>
          </h4>
          <p className="c3">
            <span className="c6">
              California residents have the option to authorize an
              &quot;authorized agent&quot; to make requests on their behalf. To
              ensure this, we will need the authorized agent to present a
              written authorization verifying their authority.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              &quot;Sales&quot; of Personal Information in the Previous 12
              Months: Within the last twelve months, we have engaged in the
              &quot;sale&quot; (as per the CCPA&#39;s definition) of the
              subsequent categories of Personal Information:
            </span>
          </p>
          <ul className="c9 lst-kix_98tz5drrtoa-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">Identifiers</span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Personal information categories in the California Customer
                Records statute
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Characteristics of protected classifications under California or
                federal law
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">Commercial information</span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">Internet or similar network activity</span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">Geolocation data</span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Professional or employment-related information
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Inferences derived from other personal information
              </span>
            </li>
          </ul>
          <p className="c3">
            <span className="c6">
              We have conveyed Personal Information to the third parties or
              categories of third parties as outlined on our website at
              https://Lendmarx, LLC/service-providers/.
            </span>
          </p>
          <h4 className="c8" id="h.vxxpoqydkhj2">
            <span className="c1">
              Privacy Rights Notice For Nevada Residents Only
            </span>
          </h4>
          <p className="c3">
            <span>
              Residents of Nevada hold the right to opt-out of the sale of their
              Personal Information. To exercise this right, kindly send an email
              to{" "}
            </span>
            <span className="c26">
              <a className="c14" href="mailto:hello@lendmarx.com">
                hello@lendmarx.com
              </a>
            </span>
            <span className="c6">
              &nbsp;with &quot;NV Sale Opt Out Request&quot; in the subject
              line. Please be aware that we will undertake reasonable measures
              to verify your identity and the validity of the request. We are
              committed to responding to your request within sixty (60) days.
              Once verified, your opt-out preference will be maintained in the
              event of any changes to our practices. Please be advised that this
              right does not extend to business-to-business customers,
              employment applicants, independent contractors with us, or
              instances where the disclosure of Personal Information aligns with
              the reasonable expectations of the Nevada resident, considering
              the context of the submission.
            </span>
          </p>
          <h4 className="c8" id="h.ot1fjay87hqf">
            <span className="c1">Notices; Opting Out</span>
          </h4>
          <p className="c3">
            <span className="c6">
              When you provide us with your email address, whether by
              &quot;following,&quot; &quot;liking,&quot; linking your account to
              our Website or Service on a third-party website or network, or
              similar actions, you are granting consent for us to use your email
              address for sending you Service-related notices via email. This
              includes any notices that may be required by law (such as
              notifications of data privacy or security incidents), instead of
              using postal mail for communication. Additionally, you acknowledge
              that we may send you notifications related to activities
              concerning the Service, the Website, your Personal Information, or
              any aspect of our interaction, using the email address you have
              provided, in accordance with relevant privacy settings.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              We may utilize your email address to dispatch other messages or
              content, such as newsletters, updates to Service features, or
              special offers, among other things. Should you prefer not to
              receive such email communications, you can opt out by sending us
              an email with your opt-out request or by utilizing the
              &quot;unsubscribe&quot; link located at the bottom of our
              e-newsletters, if available. It&#39;s important to note that
              opting out might result in you not receiving email messages
              concerning updates, improvements, special features, announcements,
              or offers. However, you cannot opt out of emails related to the
              Service itself.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              You maintain the ability to add, modify, or remove your
              information as previously described. In cases where you update
              your information, a copy of the unaltered information might still
              be retained in our records. If you wish to have your account
              deleted, you can request this by emailing us. Please remember that
              it is your responsibility to ensure that your current email
              address is kept up to date with us.
            </span>
          </p>
          <h4 className="c8" id="h.sdcss7apvlc">
            <span className="c1">Inquiries or Concerns?</span>
          </h4>
          <p className="c3">
            <span className="c6">
              If you have inquiries regarding this Privacy Policy or any
              concerns about the management of your Personal Information, please
              don&#39;t hesitate to get in touch with our Customer Care
              department. You can do so by telephone, through written
              correspondence, or via email. We are committed to addressing your
              questions and informing you of any measures taken to resolve the
              concerns you&#39;ve raised. If, for any reason, our Customer Care
              department is unable to provide assistance, we encourage you to
              reach out to our Privacy Officer using the contact details
              provided below.
            </span>
          </p>
          <p className="c3">
            <span className="c6">
              In the event that you find our response unsatisfactory, you have
              the option to make a written submission to the Privacy
              Commissioner in your jurisdiction. Your satisfaction and the
              security of your Personal Information are our top priorities.
            </span>
          </p>
          <h4 className="c8" id="h.cennwyndyrxh">
            <span className="c1">Contact Information</span>
          </h4>
          <p className="c3">
            <span className="c6">
              Contact us for questions on our Privacy Policy. Our contact
              information is as follows:
            </span>
          </p>
          <ul className="c9 lst-kix_x567k9uaazr1-0 start">
            <li className="c2 li-bullet-0">
              <span className="c15">By Email to: </span>
              <span className="c12">
                <a className="c14" href="mailto:privacy@lendmarx.com">
                  privacy@lendmarx.com
                </a>
              </span>
            </li>
          </ul>
          <p className="c3">
            <span className="c6">Lendmarx, LLC</span>
          </p>
          <p className="c13">
            <span className="c6">Attention: Privacy Officer</span>
          </p>
          <p className="c13">
            <span className="c6">701 Market Street, Suite 111</span>
          </p>
          <p className="c13">
            <span className="c6">St. Augustine, FL 32801</span>
          </p>
          <p className="c13">
            <span>Toll-Free: </span>
            <span>1 (833) 472-1780</span>
          </p>
          <p className="c22">
            <span className="c11"></span>
          </p>
          <h4 className="c8" id="h.wzgo68htu02a">
            <span className="c1">Revisions to this Privacy Policy</span>
          </h4>
          <p className="c3">
            <span className="c6">
              Periodically, we may update and modify this Privacy Policy. Any
              changes made will be reflected in the revised version of the
              policy, which will be posted on our Lendmarx, LLC website. These
              updates will come into effect as soon as they are posted. To stay
              informed about any modifications, it&#39;s your responsibility to
              check our website regularly. Your continued use of our services
              following any changes signifies your acceptance of the updated
              Privacy Policy.
            </span>
          </p>
          <h4 className="c8" id="h.2x281p1dk01">
            <span className="c1">Interpretation of this Privacy Policy</span>
          </h4>
          <p className="c3">
            <span className="c6">
              This Privacy Policy provides examples but is not limited to those
              examples. Therefore, when the term &quot;including&quot; is used,
              it encompasses a broader scope and is not limited to the mentioned
              examples.
            </span>
          </p>
          <p className="c7 c24">
            <span className="c6"></span>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
